exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-parts-electronics-tsx": () => import("./../../../src/pages/parts/electronics.tsx" /* webpackChunkName: "component---src-pages-parts-electronics-tsx" */),
  "component---src-pages-parts-floatwheel-tsx": () => import("./../../../src/pages/parts/floatwheel.tsx" /* webpackChunkName: "component---src-pages-parts-floatwheel-tsx" */),
  "component---src-pages-parts-gt-tsx": () => import("./../../../src/pages/parts/gt.tsx" /* webpackChunkName: "component---src-pages-parts-gt-tsx" */),
  "component---src-pages-parts-index-tsx": () => import("./../../../src/pages/parts/index.tsx" /* webpackChunkName: "component---src-pages-parts-index-tsx" */),
  "component---src-pages-parts-misc-tsx": () => import("./../../../src/pages/parts/misc.tsx" /* webpackChunkName: "component---src-pages-parts-misc-tsx" */),
  "component---src-pages-parts-pint-tsx": () => import("./../../../src/pages/parts/pint.tsx" /* webpackChunkName: "component---src-pages-parts-pint-tsx" */),
  "component---src-pages-parts-xr-tsx": () => import("./../../../src/pages/parts/xr.tsx" /* webpackChunkName: "component---src-pages-parts-xr-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */)
}

